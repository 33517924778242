import { dialogMgr } from 'widgets/toolbox/dialogMgr';
import { timeout } from 'widgets/toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import {
    appendParamsToUrl,
    getUrlParams,
    removeParamFromURL
} from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    TAB: 9,
    DOWN: 40,
    UP: 38,
    RIGHT: 39,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36
});

/**
 * @typedef {ReturnType <typeof import('widgets/search/RefinementsPanelToggle').default>} RefinementsPanelToggle
 * @typedef {ReturnType <typeof import('widgets/search/RefinementMenuItem').default>} RefinementMenuItem
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param ListAccessibility Base widget for extending
 * @returns RefinementsPanel widget
 */
export default function (ListAccessibility: ReturnType <typeof import('widgets/global/ListAccessibility').default>) {
    /**
     * @category widgets
     * @subcategory search
     * @class RefinementsPanel
     * @augments ListAccessibility
     * @classdesc Represents RefinementsPanel component with next features:
     * 1. Allow open/close refinement panel
     * 2. Allow open next/previous level panel
     * 3. Allow close panel on Backdrop
     * 4. Allow update panel using mustache template
     * 5. Support keyboard navigation for accessibility
     *
     * RefinementsPanel widget should contain {@link RefinementMenuItem} widgets that implement one refinement menu item.
     * Widget has next relationship:
     * * Open refinement panel using method {@link RefinementsPanel#openPanel} by global {@link event:"refinement.panel.open"} from {@link RefinementsPanelToggle} widget.
     * * Open previous level refinement panel using method {@link RefinementsPanel#previousLevel} by global {@link event:"refinement.panel.previous.level"} from {@link RefinementMenuItem} widget.
     * * Open next level refinement panel using method {@link RefinementsPanel#nextLevel} by global {@link event:"refinement.panel.next.level"} from {@link RefinementMenuItem} widget.
     * * Update refinement panel using method {@link RefinementsPanel#updatePanel} by global {@link event:"refinement.panel.update"} from {@link RefinementMenuItem} widget.
     * @property {string} data-widget - Widget name "refinementsPanel"
     * @property {string} data-event-keydown - keydown handler
     * @property {string} data-show-ajax-url - URL for AJAX update
     * @property {string} [data-classes-backdrop-active=m-active] - class added/removed on 'self' element on openPanel/closePanel
     * @property {string} [data-classes-dialog-open=m-opened] - class added/removed on ref element 'dialog' on openPanel/closePanel
     * @property {string} [data-classes-active-level=m-active_level_] - prefix for class that is added/removed from 'panel' ref element when navigating between panels
     * @property {string} [data-item-switch-timeout=500] - timeout (in ms) between panels focus changes
     * @example <caption>Example of RefinementsPanel widget usage</caption>
     *<aside
     *    class="l-plp-refinements_slide b-refinements_slide_panel ${!searchHasProducts ? 'm-no_results' : ''}"
     *    data-widget="refinementsPanel"
     *    data-event-keydown="handleKeydown"
     *    data-forward-to-parent="updateView"
     *    data-widget-event-noresult="handleNoResult"
     *    data-show-ajax-url="${URLUtils.url('Search-ShowAjax')}"
     *    data-panel-container="panel"
     *    data-drag-direction-x="right"
     *    id="refinements-panel"
     *    aria-labelledby="refinements-panel-title-xl"
     *    data-tau="refinements_menu_panel"
     *>
     *      <div
     *          role="none"
     *          data-widget="refinementMenuItem"
     *          data-forward-to-parent="updateView:closePanel"
     *          data-refinement-selected="{{${'#'}selectedFiltersText}}true{{/selectedFiltersText}}{{^selectedFiltersText}}false{{/selectedFiltersText}}"
     *          data-title="{{displayName}}"
     *          data-refinement-id="{{refinementId}}"
     *      >
     *          refinementMenuItem content....
     *      </div>
     *</aside>
     */
    class RefinementsPanel extends ListAccessibility {
        prefs() {
            return {
                classesBackdropActive: 'm-active',
                classesDialogOpen: 'm-opened',
                classesActiveLevel: 'm-active_level_',
                itemSwitchTimeout: 500,
                showAjaxUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Widget logic initialization
         * @listens "refinement.panel.open"
         * @listens "refinement.panel.previous.level"
         * @listens "refinement.panel.next.level"
         * @listens "refinement.panel.update"
         * @returns {void}
         */
        init() {
            super.init();
            this.eventBus().on('refinement.panel.open', 'openPanel');
            this.eventBus().on('refinement.panel.previous.level', 'previousLevel');
            this.eventBus().on('refinement.panel.next.level', 'nextLevel');
            this.eventBus().on('refinement.panel.update', 'updatePanel');
        }

        /**
         * @description Update Panel
         * @emits RefinementsPanel#noresult
         * @param {object} data Update data
         * @returns {void}
         */
        updatePanel(data) {
            this.ref('dialog').attr('aria-busy', 'true');
            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 2).addClass(this.prefs().classesActiveLevel + 1);

            // apply filters and results count only after panel is change back
            this.onDestroy(timeout(() => {
                getJSONByUrl(data.url).then(res => {
                    const param = getUrlParams(data.url);

                    if (Object.keys(res).length && res.count) {
                        this.render(
                            'template',
                            {
                                ...res,
                                updateUrl: removeParamFromURL(appendParamsToUrl(this.prefs().showAjaxUrl, param), 'ajax')
                            },
                            this.ref('dialog')
                        ).then(() => {
                            this.ref('dialog').addClass(this.prefs().classesDialogOpen);
                            this.ref('dialog').attr('aria-busy', 'false');
                            this.defineItems();
                            this.setFocusToFirstItem();
                        });
                    } else {
                        /**
                         * @description Event to
                         * @event RefinementsPanel#noresult
                         */
                        this.emit('noresult');
                    }
                });
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Select next level
         * @param {object} data data
         * @returns {void}
         */
        nextLevel(data) {
            this.ref('dialog').attr('aria-busy', 'true');

            this.getById('subpanel', (subPanel) => {
                if (data.panelName) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSubmenuTitle' does not exist on type ... Remove this comment to see the full error message
                    subPanel.setSubmenuTitle(data.panelName);
                }

                if (data.refinementId) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSubmenuId' does not exist on type 'Wi... Remove this comment to see the full error message
                    subPanel.setSubmenuId(data.refinementId);
                }

                if (data.htmlMarkup) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSubmenuHTML' does not exist on type '... Remove this comment to see the full error message
                    subPanel.setSubmenuHTML(data.htmlMarkup);
                }

                subPanel.show();
            });

            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 1).addClass(this.prefs().classesActiveLevel + 2);
            this.onDestroy(timeout(() => {
                this.ref('dialog').attr('aria-busy', 'false');
                this.getById('subpanel', (subPanel) => {
                    if (data.showRefinementControls) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'showRefinementControls' does not exist o... Remove this comment to see the full error message
                        subPanel.showRefinementControls(true);
                    } else {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideRefinementControls' does not exist o... Remove this comment to see the full error message
                        subPanel.hideRefinementControls();
                    }

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'markSubmenuOpened' does not exist on typ... Remove this comment to see the full error message
                    subPanel.markSubmenuOpened();

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'defineItems' does not exist on type 'Wid... Remove this comment to see the full error message
                    subPanel.defineItems();

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocusToFirstItem' does not exist on t... Remove this comment to see the full error message
                    subPanel.setFocusToFirstItem();

                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'parseUrlRefinementParameter' does not ex... Remove this comment to see the full error message
                    subPanel.parseUrlRefinementParameter();
                });
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Select previous level
         * @returns {void}
         */
        previousLevel() {
            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 2);
            this.ref('panel').addClass(this.prefs().classesActiveLevel + 1);
            this.onDestroy(timeout(() => {
                this.setFocusToCurrentItem();
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Open Menu Panel
         * @param {object} data data
         * @returns {Promise<void>} Promise object represents panel rendering result
         */
        openPanel(data) {
            this.ref('dialog').attr('aria-busy', 'true');
            this.getRefinementsModel();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'model' does not exist on type 'Refinemen... Remove this comment to see the full error message
            return this.render('template', this.model, this.ref('dialog'))
                .then(() => {
                    this.ref('self').addClass(this.prefs().classesBackdropActive);
                    this.ref('dialog').addClass(this.prefs().classesDialogOpen);

                    this.onDestroy(timeout(() => {
                        this.defineItems();
                        this.ref('dialog').attr('aria-busy', 'false');

                        if (data.focusToLastItem) {
                            this.setFocusToLastItem();
                        } else {
                            this.setFocusToFirstItem();
                        }
                    }, this.prefs().itemSwitchTimeout));

                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'this' is not assignable to param... Remove this comment to see the full error message
                    dialogMgr.openDialog(this);
                    this.ref('dialog').attr('role', false);
                    this.ref('dialog').attr('aria-modal', false);
                });
        }

        /**
         * @description Close Panel
         * @emits "refinement.panel.close"
         * @returns {void}
         */
        closePanel() {
            this.ref('self').removeClass(this.prefs().classesBackdropActive);
            this.ref('dialog').removeClass(this.prefs().classesDialogOpen);

            /**
             * @description Global event to close refinement panel
             * @event "refinement.panel.close"
             */
            this.eventBus().emit('refinement.panel.close');

            dialogMgr.closeDialog();
        }

        /**
         * @description Get Refinements Model
         * @returns {void}
         */
        getRefinementsModel() {
            let refinementsModel = this.ref('refinementsModel').getText().trim() || '';

            try {
                refinementsModel = JSON.parse(`"${refinementsModel}"`);

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'model' does not exist on type 'Refinemen... Remove this comment to see the full error message
                this.model = JSON.parse(refinementsModel);
            } catch (e) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'model' does not exist on type 'Refinemen... Remove this comment to see the full error message
                this.model = {};
            }
        }

        /**
         * @description Close On Backdrop handler
         * @listens dom#click
         * @param {RefElement} ref - interacted element
         * @param {Event} event - event object
         * @returns {void}
         */
        closeOnBackdrop(ref, event) {
            if (event.target === this.ref('self').get()) {
                this.closePanel();
            }
        }

        /**
         * @description Cancel Handler
         * @listens dom#click
         * @returns {void}
         */
        cancel() {
            this.closePanel();
        }

        /**
         * @description Refresh Handler
         * @returns {void}
         */
        onRefresh() {
            super.onRefresh();
            dialogMgr.closeDialog();
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.TAB:
                    this.closePanel();

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:

                    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                    if ('openMenu' in this.currentItem) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'openMenu' does not exist on type 'never'... Remove this comment to see the full error message
                        this.currentItem.openMenu();
                        preventEventActions = true;
                    }

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return RefinementsPanel;
}
