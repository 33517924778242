import {
    appendParamsToUrl,
    getUrlParams,
    removeParamFromURL
} from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/search/RefinementMenuItem').default>} RefinementMenuItemBase
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<typeof import('widgets/Widget').default>} widget
 */

/**
 * @description Extend RefinementMenuItem implementation
 * @param {RefinementMenuItemBase} RefinementMenuItemBase Base widget for extending
 * @returns {typeof RefinementMenuItem} RefinementMenuItem extending widget
 */
export default function (RefinementMenuItemBase) {
    /**
     * @class RefinementMenuItem
     * @augments RefinementMenuItemBase
     */
    class RefinementMenuItem extends RefinementMenuItemBase {
        prefs() {
            return {
                updateViewAfterApplyRefinements: false,
                showAjaxUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Calculate products by selected refinements
         * @returns {number} count
         */
        calculateCountSelectedRefinements() {
            return this.getRefinements().reduce((count, refinement) => {
                return count + (refinement.selected ? refinement.getCount() : 0);
            }, 0);
        }

        /**
         * @description Calculate products by all refinements
         * @returns {number} count
         */
        calculateCountAllRefinements() {
            return this.getRefinements().reduce((count, refinement) => {
                return count + refinement.getCount();
            }, 0);
        }

        /**
         * @description Update Refinement Controls
         * @param {widget} [clickedItem] clicked refinement item
         * @returns {void}
         */
        updateRefinementControls(clickedItem) {
            if (this.hasCheckedRefinements()) {
                const hasChangedRefinements = this.hasChangedRefinements();

                this.updateCountButtonApply(this.calculateCountSelectedRefinements());
                this.showRefinementControls(!hasChangedRefinements);
            } else {
                this.updateCountButtonApply(this.calculateCountAllRefinements());
                this.hideRefinementControls(true);
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(currentItem: any) => void' is n... Remove this comment to see the full error message
            this.defineItems(clickedItem || this.currentItem);
            this.setFocusToCurrentItem();
        }

        /**
        * @description Clear Refinements
        * @returns {void}
        */
        clearRefinements() {
            super.clearRefinements();
            this.updateCountButtonApply(this.calculateCountAllRefinements());
        }

        /**
         * @description Set text with count product to button apply
         * @param {number} count product
         */
        updateCountButtonApply(count) {
            this.getById('applyBtn', (button) => {
                button.setText(button.config.textValue.replace('{0}', count));
            });
        }

        /**
         * @description handles updates
         * @param {(RefElement|widget|undefined)} button Target element
         * @returns {void}
         */
        handleUpdate(button) {
            if (button && this.prefs().updateViewAfterApplyRefinements && this.prefs().showAjaxUrl) {
                const refinementURL = this.getRefinementsURL();
                const param = getUrlParams(refinementURL);
                const updateURL = removeParamFromURL(appendParamsToUrl(this.prefs().showAjaxUrl, param), 'ajax');
                const buttonRef = button.ref('self');

                buttonRef.data('href', updateURL);

                this.eventBus().emit('update.product.list.grid.view', buttonRef, { refinementPanelUpdate: true });
            } else {
                /**
                 * @description Event to update refinement panel
                 * @event "refinement.panel.update"
                 */
                this.eventBus().emit('refinement.panel.update', { url: this.getRefinementsURL() });
            }
        }

        /**
         * @description handles pending applied filters
         */
        handlePendingRefinements() {
            const refinements = this.getRefinements();
            const pendingApplyRefinements = refinements.filter((refinement) => refinement.prefs().pendingFilter);
            let refinedByIdeas = '';

            this.eventBus().emit('productlistingmgr.execute', productListingMgr => {
                refinedByIdeas = productListingMgr.prefs().refinedByIdeas;
            });

            this.eventBus().emit('apply.refinements.menu', pendingApplyRefinements, refinedByIdeas);
        }

        /**
         * @description Apply change
         * @listens dom#click
         * @param {(RefElement|widget|undefined)} [button] Target element
         * @returns {void}
         */
        applyChange(button) {
            const values = [];
            let type;
            let min;
            let max;

            this.getRefinements().forEach((refinement) => {
                type = refinement.attrId;

                if (refinement.selected) {
                    if (type === 'price') {
                        min = refinement.min;
                        max = refinement.max;
                    } else {
                        values.push(refinement.value);
                    }
                }
            });

            if (!this.refinementParameterMap) {
                return;
            }

            if (type === 'price') {
                if (max) {
                    this.refinementParameterMap.pmin = min;
                    this.refinementParameterMap.pmax = max;
                } else {
                    delete this.refinementParameterMap.pmin;
                    delete this.refinementParameterMap.pmax;
                }
            } else if (values.length) {
                this.refinementParameterMap[type] = values;
            } else {
                delete this.refinementParameterMap[type];
            }

            this.handlePendingRefinements();
            this.handleUpdate(button);
        }
    }

    return RefinementMenuItem;
}
