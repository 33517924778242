import {
    appendParamToURL,
    getUrlParams
} from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    ESC: 27,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39
});

type TRefinement = ReturnType <typeof import('widgets/search/Refinement').default>;
type refinementInstance = InstanceType <TRefinement>;

/**
 * @param ListAccessibility Base widget for extending
 * @returns RefinementMenuItem widget
 */
export default function (ListAccessibility: ReturnType<typeof import('widgets/global/ListAccessibility').default>) {
    /**
     * @category widgets
     * @subcategory search
     * @class RefinementMenuItem
     * @augments ListAccessibility
     * @classdesc Represents Refinement Menu Item with specific logic for submenus, keyboard navigation.
     * Represents RefinementMenuItem component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Show control button according to selected refinements
     * 3. Build update url for refinement panel
     * 4. Allow apply/clear refinements
     * 5. Allow navigate thought submenus
     * 6. Using mustache template to render refinements
     *
     * RefinementMenuItem widget should be inside {@link RefinementsPanel} widgets that implement refinement panel.
     * RefinementMenuItem widget should contain {@link Refinement} widget inside.
     * @property {string} data-widget - Widget name `refinementMenuItem`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {boolean} [data-prevent-show-submenu=false] - prevent showing submenu
     * @property {boolean} [data-prevent-refinement-selected=false] - prevent showing submenu
     * @property {string} data-refinebar-url - refine bar url
     * @property {string} data-widget-event-previous - Previous panel handler
     * @property {string} data-update-url - update url
     * @example <caption>Example of RefinementMenuItem widget usage</caption>
     * <div
     *     data-widget="refinementMenuItem"
     *     data-forward-to-parent="updateView:closePanel"
     *     data-refinement-selected="{{${'#'}selectedFiltersText}}true{{/selectedFiltersText}}{{^selectedFiltersText}}false{{/selectedFiltersText}}"
     *     data-title="{{displayName}}"
     * >
     *     <div
     *         class="b-slide_panel-item"
     *         role="menuitem"
     *         tabindex="0"
     *         aria-haspopup="true"
     *         aria-expanded="false"
     *         data-ref="itemLink"
     *         data-event-click.prevent="handleClick"
     *         data-tau="refinements_button"
     *     >
     *     <div class="b-slide_panel-link">
     *         <span class="b-slide_panel-name">
     *             {{displayName}}
     *         </span>
     *
     *         <div
     *             class="b-slide_panel-applied_filters"
     *             data-tau="applied_filter_value"
     *         >
     *             {{${'#'}isCategoryRefinement}}
     *                 {{${'#'}category}}
     *                     {{category.name}}
     *                 {{/category}}
     *             {{/isCategoryRefinement}}
     *             {{^isCategoryRefinement}}
     *                 {{selectedFiltersText}}
     *             {{/isCategoryRefinement}}
     *         </div>
     *     </div>
     *     <span class="b-slide_panel-item_icon">
     *         <isinclude template="/common/icons/standalone/arrowForward" />
     *     </span>
     * </div>
     */
    class RefinementMenuItem extends ListAccessibility {
        isSubmenuOpen = false;

        refinementParameterMap: {
            pmin?: any;
            pmax?: any;
        } = {};

        prefs() {
            return {
                preventShowSubmenu: false,
                refinementSelected: false,
                submenu: 'submenu',
                updateUrl: '',
                currentUrl: '',
                refinebarUrl: '',
                title: '',
                refinementId: '',
                ...super.prefs()
            };
        }

        /**
         * @description Has submenu
         * @returns Return true if submenu exist
         */
        hasSubmenu(): boolean {
            return this.has(this.prefs().submenu) && !this.prefs().preventShowSubmenu;
        }

        /**
         * @description Initialize widget logic
         */
        init(): void {
            this.defineItems();
            this.isSubmenuOpen = false;
        }

        /**
         * @description Get refinement widgets
         * @returns {Array<refinement>} Array of refinement widgets
         */
        getRefinements(): Array<refinementInstance> {
            const Refinement: TRefinement = <TRefinement> this.getConstructor('refinement');
            let refinement = [];

            if (this.items) {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Widget[]' is not assignable to type 'never[]... Remove this comment to see the full error message
                refinement = this.items.filter((item) => {
                    if (item instanceof Refinement) {
                        return item.isAttributeRefinement();
                    }

                    return false;
                });
            }

            return refinement;
        }

        /**
         * @description Has Checked Refinements
         * @returns {boolean} Has Checked Refinements Flag
         */
        hasCheckedRefinements(): boolean {
            return this.getRefinements().some((refinement) => refinement.selected);
        }

        /**
         * @description Detects if selected refinements list was changed
         * @returns {boolean} Has changed refinements
         */
        hasChangedRefinements(): boolean {
            const changedRefinements = this.getRefinements().filter((refinement) => {
                const isNewRefinementSelected = refinement.selected && !refinement.prefs().checked;
                const isOldRefinementUnselected = !refinement.selected && refinement.prefs().checked;

                return isNewRefinementSelected || isOldRefinementUnselected;
            });

            return changedRefinements.length !== 0;
        }

        /**
         * @description Uncheck Refinements
         * @returns {void}
         */
        uncheckRefinements(): void {
            this.getRefinements().forEach((refinement) => {
                refinement.uncheck();
            });
        }

        /**
         * @description Clear Refinements
         * @returns {void}
         */
        clearRefinements(): void {
            this.uncheckRefinements();
            this.hideRefinementControls(true);

            this.defineItems();
            this.setFocusToFirstItem();
        }

        /**
         * @description Mark Submenu Opened
         * @returns {void}
         */
        markSubmenuOpened(): void {
            this.isSubmenuOpen = true;
            this.ref('self').attr('aria-expanded', 'true');
        }

        /**
         * @description Has opened submenu
         * @returns {boolean|undefined} Return true if submenu opened
         */
        hasOpenedSubmenu(): boolean | undefined {
            return this.isSubmenuOpen;
        }

        /**
         * @description Open next menu level if exist
         * @emits "refinement.panel.next.level"
         * @returns {void}
         */
        openMenu(): void {
            if (this.hasSubmenu()) {
                const itemName = this.prefs().title;
                let submenuHTML;

                this.has(this.prefs().submenu, (submenu) => {
                    const submenuElement = submenu.get();

                    if (submenuElement) {
                        submenuHTML = submenuElement.innerHTML;
                    }
                });
                /**
                 * @description Event to open next level panel
                 * @event "refinement.panel.next.level"
                 */
                this.eventBus().emit('refinement.panel.next.level', {
                    panelName: itemName,
                    refinementId: this.prefs().refinementId,
                    htmlMarkup: submenuHTML,
                    showRefinementControls: this.prefs().refinementSelected
                });
            }
        }

        /**
         * @description Show Refinement Controls
         * @param {boolean} [showBackButton=false] - Is back button should be shown
         * @returns {void}
         */
        showRefinementControls(showBackButton = false): void {
            this.getById('clearBtn', (button) => button.show());
            this.getById('applyBtn', (button) => button.toggle(!showBackButton));
            this.getById('backBtn', (button) => button.toggle(showBackButton));
        }

        /**
         * @description Hide Refinement Controls
         * @param {boolean} [showApply] Show Apply button flag
         * @returns {void}
         */
        hideRefinementControls(showApply: boolean): void {
            this.getById('clearBtn', (button) => button.hide());

            if (showApply) {
                this.getById('applyBtn', (button) => button.show());
                this.getById('backBtn', (button) => button.hide());
            } else {
                this.getById('applyBtn', (button) => button.hide());
                this.getById('backBtn', (button) => button.show());
            }
        }

        /**
         * @description Set Submenu Title
         * @param {string} submenuTitle Submenu Title
         * @returns {void}
         */
        setSubmenuTitle(submenuTitle: string): void {
            this.has('title', (submenu) => {
                submenu.setText(submenuTitle);
            });
            this.ref('self').attr('aria-label', submenuTitle);
        }

        /**
         * @description Set Submenu id attribute to reference to it from aria-owns
         * @param {string} refinementId refinement id
         * @returns {void}
         */
        setSubmenuId(refinementId: string): void {
            this.ref('self').attr('id', 'submenu-' + refinementId);
        }

        /**
         * @description Set Submenu HTML
         * @param {string} submenuHTML Submenu HTML
         * @returns {void}
         */
        setSubmenuHTML(submenuHTML: string): void {
            this.has(this.prefs().submenu, (submenu) => {
                const submenuElement = submenu.get();

                if (submenuElement) {
                    submenuElement.innerHTML = submenuHTML;
                }
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} event Event object
         */
        handleClick(_: HTMLElement, event: Event) {
            if (this.hasSubmenu()) {
                event.preventDefault();
                this.openMenu();
            }
        }

        /**
         * @description Close Submenu
         * @listens dom#click
         * @emits "refinement.panel.previous.level"
         * @returns {void}
         */
        closeSubmenu(): void {
            /**
             * @description Event to open previous level refinement panel
             * @event "refinement.panel.previous.level"
             */
            this.eventBus().emit('refinement.panel.previous.level');
            this.ref('self').attr('aria-expanded', 'false');
            this.isSubmenuOpen = false;
        }

        /**
         * @description Parse Url Refinement Parameter
         * @returns {void}
         */
        parseUrlRefinementParameter(): void {
            const paramMap = <{[ key: string ]: string}> getUrlParams(this.prefs().updateUrl || this.prefs().currentUrl);

            this.refinementParameterMap = {};
            Object.keys(paramMap).forEach((paramKey) => {
                if (this.refinementParameterMap) {
                    if (paramKey.includes('prefn')) {
                        this.refinementParameterMap[paramMap[paramKey]] = paramMap[paramKey.replace('prefn', 'prefv')].split('|');
                    } else if (!paramKey.includes('prefv')) {
                        this.refinementParameterMap[paramKey] = paramMap[paramKey];
                    }
                }
            });
        }

        /**
         * @description Update Refinement Controls
         * @returns {void}
         */
        updateRefinementControls(): void {
            if (this.hasCheckedRefinements()) {
                const hasChangedRefinements = this.hasChangedRefinements();

                this.showRefinementControls(!hasChangedRefinements);
            } else {
                this.hideRefinementControls(true);
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(currentItem: any) => void' is n... Remove this comment to see the full error message
            this.defineItems(this.currentItem);
            this.setFocusToCurrentItem();
        }

        // @ts-expect-error ts-migrate(2416) FIXME: Property 'currentItem' in type 'RefinementMenuItem... Remove this comment to see the full error message
        currentItem(currentItem: any) {
            throw new Error('Method not implemented.');
        }

        /**
         * @description Apply change
         * @listens dom#click
         * @returns {void}
         */
        applyChange(): void {
            const values = [];
            let type;
            let min;
            let max;

            this.getRefinements().forEach((refinement) => {
                type = refinement.attrId;

                if (refinement.selected) {
                    if (type === 'price') {
                        min = refinement.min;
                        max = refinement.max;
                    } else {
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        values.push(refinement.value);
                    }
                }
            });

            if (!this.refinementParameterMap) {
                return;
            }

            if (type === 'price') {
                if (max) {
                    this.refinementParameterMap.pmin = min;
                    this.refinementParameterMap.pmax = max;
                } else {
                    delete this.refinementParameterMap.pmin;
                    delete this.refinementParameterMap.pmax;
                }
            } else if (values.length) {
                this.refinementParameterMap[type] = values;
            } else {
                delete this.refinementParameterMap[type];
            }

            /**
             * @description Event to update refinement panel
             * @event "refinement.panel.update"
             */
            this.eventBus().emit('refinement.panel.update', { url: this.getRefinementsURL() });
        }

        /**
         * @description Get applied refinements URL
         * @returns {string} url - Refinements URL
         */
        getRefinementsURL(): string {
            let url = this.prefs().refinebarUrl;
            let counter = 1;

            if (!this.refinementParameterMap) {
                return url;
            }

            Object.keys(this.refinementParameterMap).forEach((key) => {
                if (this.refinementParameterMap && !['start', 'sz'].includes(key)) {
                    if (Array.isArray(this.refinementParameterMap[key])) {
                        url = appendParamToURL(url, 'prefn' + counter, key);
                        url = appendParamToURL(url, 'prefv' + counter, this.refinementParameterMap[key].join('|'));
                        counter++;
                    } else {
                        url = appendParamToURL(url, key, this.refinementParameterMap[key]);
                    }
                }
            });

            return url;
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_: HTMLElement, event: KeyboardEvent): void {
            let preventEventActions = false;

            if (!this.hasOpenedSubmenu()) {
                return;
            }

            switch (event.keyCode) {
                case keyCode.LEFT:
                    this.closeSubmenu();
                    preventEventActions = true;
                    break;
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;
                    break;
                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;
                    break;
                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;
                    break;
                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;
                    break;
                case keyCode.RIGHT:
                    preventEventActions = true;
                    break;
                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return RefinementMenuItem;
}
